import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import React from "react";
import App from "./App";
import { loginRequest, msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const AuthenticatedApp = () => (
  <MsalProvider instance={msalInstance}>
    <AuthApp />
  </MsalProvider>
);

export default AuthenticatedApp;

const AuthApp = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  return isAuthenticated ? (
    <App />
  ) : (
    <div style={{ padding: 20 }}>
      <h2>You need to be signed in to access this site.</h2>
      <div>
        <button
          type="button"
          className="btn btn-primary"
          style={{ cursor: "pointer" }}
          onClick={() =>
            instance.loginRedirect(loginRequest).catch((e) => {
              console.log(e);
            })
          }
        >
          Sign In
        </button>
      </div>
    </div>
  );
};

export const getUser = () => {
  const { accounts } = useMsal();
  return accounts[0];
};

export const Greeting = () => {
  const user = getUser();

  return user ? (
    <div>
      <button
        type="button"
        className="btn btn-danger"
        style={{ float: "right", cursor: "pointer" }}
        onClick={() =>
          confirm("Are you sure you want to sign out?") &&
          msalInstance.logoutRedirect()
        }
      >
        Sign Out
      </button>
      <h3>Hello {user.name}</h3>
      <br />
    </div>
  ) : (
    <></>
  );
};

export const UserInfo = ({ isQuiz }) => {
  const user = getUser();

  return user ? (
    <div
      style={{
        color: "#fff",
        backgroundColor: isQuiz ? "#1e4b8a" : "#3f9bd9",
        textAlign: "right",
        paddingRight: 10,
        marginBottom: -10,
      }}
    >
      <b
        onDoubleClick={() => {
          !isQuiz &&
            confirm("Are you sure you want to sign out?") &&
            msalInstance.logoutRedirect();
        }}
      >
        {user.name}
      </b>
    </div>
  ) : (
    <></>
  );
};
